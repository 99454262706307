<template>
   <div class="max-width">
      <div class="categories">
         <div class="category-list" ref="categoryList">
            <List/>
         </div>
      </div>
      <Modal v-model="isOpenModalAdvice" :modalContentClasses="['max-width-content']">
         <AddTipModal v-if="showAddTipModal" @onCancel="isOpenModalAdvice = false" />
      </Modal>
      <SWSnackBar ref="snackbar" />
   </div>
</template>

<script>
import Modal from '../../components/UI/Modal.vue';
//import actions from '../../store/action-types';
import AddTipModal from '../AdvicesApp/AddTipModal.vue';
import List from '../../components/List/List.vue';
import { SWSnackBar } from 'sw-ui-kit';
//import log from '../../logger/logger';

export default {
   provide() {
      return {
         openAdviceModal: this.openAdviceModal,
         snackBar: this.showMessage,
      };
   },
   components: {
      Modal,
      AddTipModal,
      SWSnackBar,
      List,
   },

   data() {
      return {
         // shit better not to touch
         isOpenModalAdvice: false,
         showAddTipModal: false,
         resolve: null,
         // end shit better not to touch
         list: [],
         hidden: [],
      };
   },
   methods: {
      showMessage(message) {
         this.$refs.snackbar.showSnackbar(message, 5000);
      },
      openAdviceModal() {
         this.isOpenModalAdvice = true;
         this.showAddTipModal = true;
         return new Promise(resolve => {
            this.resolve = resolve;
         });
      },
   },
   mounted(){
  //    log(this.$http, { message: 'visited page /habits', level: 'info' });

   },
   async created() {
      const categories = await this.$http.get('/resources/categories');
      const category = categories.data.filter(el => el.name === 'Habits')[0];
      this.$store.commit(`app/UPDATE_CURRENT_CATEGORY`, { id: category._id, name: category.name });
   },

   watch: {
      isOpenModalAdvice() {
         //! DO NOT TOUCH
         if (!this.isOpenModalAdvice) {
            this.resolve();
         }
      },
   },

   computed: {
      category() {
         return this.$store.getters['app/getCurrentCategory'];
      },
   },
};
</script>

<style scoped>
.categories {
   max-width: 876px;
   width: 100%;
   margin: 0 auto;
}
</style>
